/* css for add new company modal starts */

.elementOR {
  margin: 15px !important;
  text-align: center;
}

/* .Contact-cubmitted-or {
    position: relative;
  }
  
  .Contact-cubmitted-or:before {
    content: '';
    display: block;
    width: 80px;
    height: 1px;
    background: #bec9dc;
    left: 0;
    top: 50%;
    position: absolute;
  }
  
  .Contact-cubmitted-or:after {
    content: '';
    display: block;
    width: 80px;
    height: 1px;
    background: #bec9dc;
    right: 0;
    top: 50%;
    position: absolute;
  }
  .swal2-shown .swal2-container {
    z-index: 99999 !important;
  } */
/* css for add new company modal ends */

/* css for file upload in Add New Company starts */

.or_fu {
  position: relative;
}

.or_fu:before {
  content: '';
  display: block;
  width: 75px;
  height: 1px;
  background: #bec9dc;
  right: 150px;
  top: 50%;
  position: absolute;
}

.or_fu:after {
  content: '';
  display: block;
  width: 75px;
  height: 1px;
  background: #bec9dc;
  left: 150px;
  top: 50%;
  position: absolute;
}
/* css for file upload in Add New Company ends */

.shadow-container {
  background: #ffffff !important;
  box-shadow: 0px 4px 18px rgb(0 0 0 / 10%) !important;
  border-radius: 8px !important;
  padding: 6px;
  margin-bottom: 25px !important;
}

.shadow-container .MuiPaper-rounded {
  border-radius: 0px !important;
}

.shadow-container div {
  box-shadow: none !important;
}

.shadow-container table tbody tr:first-child td [class*='-inputContainer-'] {
  background-color: #f2f2f2 !important;
}
.shadow-container table tbody tr:first-child td div {
  background-color: #f2f2f2 !important;
}

.shadow-container table tbody tr:first-child td [class*='-inputContainer-'] input {
  color: #000000;
}
.shadow-container table tbody tr:first-child td div input {
  color: #000000;
}

.shadow-container table tbody tr:first-child td div input {
  color: #000000;
}

.shadow-container table tbody tr:first-child td .MuiInput-underline:after {
  border-bottom: 2px solid #dddddd;
}

.shadow-container.tabs {
  width: 100% !important;
  margin-bottom: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.shadow-container.next-to-tabs {
  box-shadow: 0px 15px 18px -5px rgb(0 0 0 / 10%) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.shadow-container button.MuiIconButton-root {
  padding: 5px 10px;
  margin: 0px 5px;
  border-radius: 4px;
}
.shadow-container tr td button.MuiIconButton-root {
  padding: 3px;
  margin: auto;
  border-radius: 50%;
}

@media screen and (max-width: 680px) {
  /* .Cmt-header .Cmt-headerMain .Cmt-container [class*='makeStyles-root-'] {
      flex-wrap: wrap;
    } */
  .Cmt-header .Cmt-headerMain .Cmt-container .MuiToolbar-root {
    flex-wrap: wrap;
  }
}

.Cmt-container .MuiToolbar-root {
  padding-top: 10px;
  padding-bottom: 10px;
}
